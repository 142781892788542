import '../styles/HomePage.css'

function HomePage() {
    return (
        <section className="HomePage" id="HomePage">
            <div className="HomeBackground"/>
            <h1 className="HelloText">hello, i'm</h1>
            <h1 className="MyName">justin.</h1>
        </section>
    )
}

export default HomePage